.nodeIdColor {
  color: #c94e2f;
}

.greenTextColor {
  color: #83ae84;
}

.mheConfigDiv {
  border: 2px solid rgb(126, 121, 121);
}

.delButton, .resetButton {
  background-color: #83ae84;
}

.errorMsg {
  color: red;
}

.welcomeDiv {
  background-image: url(../assets/welcome.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  border: 3px solid #555;
  height: 99%;
  width: 99.1%;
}

.headingH3 {
  color: #c94e2f;
  size: A3;
  font-family: 'Source Sans Pro', sans-serif;
}

#disclaimer{
font-size: medium;
}

#disclaimerRunAMP{
  font-size: small;
}

.partialRun {
  color: #ffa500 !important;
}

.failedRun {
  color: #ff0000 !important;
}

.completeRun {
  color: #008000 !important;
}
